


















































































import mixins from 'vue-typed-mixins'
import Routes from '@/constants/routes'
import { frequencies } from '@/constants/subscription'

import loadRouteMixin from '@/mixins/loadRouteMixin'
import toasts from '@/mixins/toasts'
import institutionMixin from '@/mixins/institutionMixin'
import residentMixin from '@/mixins/residentMixin'
import currentUserMixin from '@/mixins/currentUserMixin'

import {
    HRow,
    HCol,
    HBtn,
    HCard,
    HQuantity,
    HTabs,
    HTab,
    HTabItem,
    HFormField,
    HInput,
    HPopover,
    HIcon
} from '@happytal/bo-ui-library'
import MainContainer from '@/views/main/MainContainer.vue'
import CatalogItemHeader from './components/CatalogItemHeader.vue'
import BackButton from '@/components/BackButton.vue'
import CatalogItemInfos from './components/CatalogItemtInfos.vue'
import CatalogItemSubscription from './components/CatalogItemSubscription.vue'
import MediaGallery from '@/components/MediaGallery.vue'
import SeparatorLine from '@/components/SeparatorLine.vue'
import DropdownSelector from '@/components/selectors/DropdownSelector.vue'
import ThumbSelector from '@/components/selectors/ThumbSelector.vue'
import CatalogItemBuyBox from './components/CatalogItemBuyBox.vue'
import SuggestionWarningPopin from '@/components/popins/SuggestionWarningPopin.vue'

import { Location } from 'vue-router'
import { PCP } from '@/types/happydom-store'
import {
    EC_ProductOffers,
    EC_Product,
    EC_ProductData,
    EC_VariantMatrix,
} from '@/types/api-missing'
import {
    CatalogBreadcrumbItem,
    MediaGalleryImage,
    FrequencySelectOption,
    BuyMode,
    VariantOption,
    VariantOptionsSelected,
    VariantValue,
    ProductOption
} from '@/types/components'
import {
    IFrontCategoryDto,
    IOfferDto,
    VariantDataDto,
} from '@happytal/ecommerce-api'
import { IResidentDto } from '@happytal/happydom-api'

export default mixins(loadRouteMixin, toasts, residentMixin, currentUserMixin, institutionMixin).extend({
    components: {
        HRow,
        HCol,
        HCard,
        HBtn,
        HQuantity,
        HFormField,
        HInput,
        HTabs,
        HTab,
        HTabItem,
        HPopover,
        HIcon,
        MainContainer,
        CatalogItemHeader,
        CatalogItemInfos,
        CatalogItemSubscription,
        MediaGallery,
        SeparatorLine,
        DropdownSelector,
        ThumbSelector,
        CatalogItemBuyBox,
        BackButton,
        SuggestionWarningPopin
    },

    props: ['resident_id', 'product_id', 'variant_id'],

    data(){
        return {
            //Variant state
            variantProducts: [] as EC_ProductOffers[],
            variantData: [] as VariantDataDto[],
            variantMatrix: {} as EC_VariantMatrix,
            variantOptions: [] as VariantOption[],
            variantOptionsSelected: null as VariantOptionsSelected | null,

            //Product state
            product: null as EC_Product | null,
            productId: '',
            productData: null as EC_ProductData | null,
            offers: [] as IOfferDto[],
            frontCategories: [] as IFrontCategoryDto[],

            //Others
            errorInBuyBox: '',
            isVisibleButtonATB: false,
            suggestionWarningOpen: false
        }
    },

    watch: {
        productPrice(productPrice): void {
            this.$store.dispatch('dataBuyBox/updateAmount', productPrice)
        }
    },

    computed: {
         // Store buy box state
        subscriptionMode(): boolean {
            return this.$store.state.dataBuyBox.subscriptionMode
        },
        suggestionEmitterName(): string {
            return this.$store.state.dataBuyBox.suggestionEmitterName
        },
        isCurrentSubscription(): boolean {
            return this.$store.state.dataBuyBox.isCurrentSubscription
        },
        isSuggestionEnabled(): boolean {
            return this.$_resident?.enableSuggestion
        },
        subscriptionFrequency(): FrequencySelectOption | null {
            return this.$store.state.dataBuyBox.subscriptionFrequency
        },
        quantity(): number {
            return this.$store.state.dataBuyBox.quantity
        },

        breadcrumbItems(): CatalogBreadcrumbItem[] {
            const breadcrumbItems: CatalogBreadcrumbItem[] = [
                {
                    type: 'catalog',
                    label: 'Catalogue',
                    route: {
                        name: Routes.ResidentCatalog
                    },
                    iconForMobile: 'BuildingStoreIcon'
                }
            ]
            if (this.category?.name) {
                const route = {
                    name: Routes.ResidentCatalog
                } as Location

                if (this.category?.id) {
                    route.params = {
                        category_id: this.category?.id ? this.category.id : ''
                    }
                }
                breadcrumbItems.push({
                    type: 'category',
                    label: this.category.name,
                    route
                })
            }

            if (this.productData?.title) {
                breadcrumbItems.push({
                    type: 'element',
                    label: this.productData.title
                })
            }

            return breadcrumbItems
        },
        backButtonLabel(): string {
            return this.category?.name ? `Retour à ${this.category.name }` : ''
        },
        backButtonRoute(): Location | undefined {
            if (!this.category) {
                return undefined
            }
            return {
                name: Routes.ResidentCatalog,
                params: {
                    category_id : this.category?.id ? this.category.id : ''
                }
            }
        },
        isEditionAllowed(): boolean {
            return this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isFamily',
                'isHappytalSupport',
                'isHappytalEmployee',
                'canEmitSuggestions'
            ])
        },

        canEmitSuggestions(): boolean {
            return this.$aclChecker.some(this.$acl, [
                'canEmitSuggestions',
            ])
        },

        areCategoriesLoaded(): boolean {
            return this.$store.state.dataCatalogCategories.categoriesLoaded as boolean
        },

        parentCategory(): IFrontCategoryDto | undefined {
            const parentCategoryId =  this.frontCategories[0]?.parentId
            return parentCategoryId ? this.$store.getters['dataCatalogCategories/getCategoryById'](parentCategoryId) : undefined
        },

        category(): IFrontCategoryDto | undefined {
            return this.frontCategories[0] ? this.frontCategories[0] : undefined
        },

        residentPcp(): PCP {
            return this.$store.getters['dataPcps/getResidentPcp'](this.$_residentId) as PCP
        },

        cheapestVariantProductMatchSelection(): EC_ProductOffers | null {
            if (this.variant_id && this.variantProducts.length === 1) return this.variantProducts[0]
            if (this.variant_id && this.variantProducts.length && this.variantOptionsSelected) {
                const codesOfSelectedOptions = Object.keys(this.variantOptionsSelected).filter( code => Boolean(this.variantOptionsSelected?.[code]) )
                const productsThatMatchSelection = this.variantProducts.filter(product => {
                    return codesOfSelectedOptions.every(code => {
                        const productOption = (product?.product?.data as EC_ProductData)?.[code]
                        return productOption?.includes(this.variantOptionsSelected?.[code])
                    })
                })
                if (productsThatMatchSelection.length) {
                    return productsThatMatchSelection.reduce( (prevVP, nextVP) => {
                        const pricePrevVP = prevVP.offers?.[0]?.price ?? 10000
                        const priceNextVP = nextVP.offers?.[0]?.price ?? 10000

                        if (pricePrevVP > priceNextVP) return nextVP
                        else return prevVP
                    })
                }
            }

            return null
        },

        productOffer(): IOfferDto | undefined {
            return this.offers[0]
        },

        productBrand(): string {
            return this.productData?.brand_label && this.productData.brand_label !== 'AUCUNE' ? this.productData.brand_label : ''
        },

        productShop(): string {
            return this.productOffer && this.productOffer.shopName || ''
        },

        productPrice(): number {
            let productPrice = this.productOffer?.price ?? 0

            //Return cheapest price if variant product AND missing options selected
            if (this.variant_id && this.variantOptionsSelected && Object.entries(this.variantOptionsSelected)?.find( ([k, v]) => v === '') ) {
                productPrice = this.cheapestVariantProductMatchSelection?.offers?.[0]?.price ?? productPrice

            }

            return Number(productPrice.toFixed(2))
        },

        productImages(): MediaGalleryImage[] {
            const productImages: MediaGalleryImage[] = []
            const { mainImage, secondaryImage, thirdImage } = this.productData || {}
            const imageTitle = this.productData?.title || ''

            if (mainImage?.source) productImages.push({ source: mainImage.source, title: `${imageTitle} 0` })
            if (secondaryImage?.source) productImages.push({ source: secondaryImage.source, title: `${imageTitle} 1` })
            if (thirdImage?.source) productImages.push({ source: thirdImage.source, title: `${imageTitle} 2` })

            return productImages
        },

        totalAmount(): number {
            return Number((this.productPrice * this.quantity).toFixed(2))
        },

        buyMode(): BuyMode {
            if (this.subscriptionMode && this.subscriptionFrequency) {
                return {
                    id: 'subscription',
                    name: `Abonnement`,
                    label: '',
                    deactivated: this.isCurrentSubscription ? true : false
                }
            }
            else return {
                id: 'once',
                name: 'Une seule fois',
                label: '',
                deactivated: false
            }
        },

        variantWidgets(): VariantOption[] {
            if (this.variantOptions.length) {
                const variantWidgets = this.variantOptions.map(variant => {
                    const { values } = variant

                    variant.selected = null

                    if (variant.behaviour === 'Pivot') {
                        for (const value of values) {

                            const optionsToCheck = {
                                ...this.variantOptionsSelected,
                                [variant.code]: value.id
                            }
                            value.disabled = false
                            value.grayed = Boolean(!this.findVariantProductByOptions(optionsToCheck))

                            if (value.id === this.variantOptionsSelected?.[variant.code]) {
                                variant.selected = value
                            }
                        }
                    } else {
                        for (const value of values) {
                            const optionsToCheck = {
                                ...this.variantOptionsSelected,
                                [variant.code]: value.id
                            }
                            value.disabled = Boolean(!this.findVariantProductByOptions(optionsToCheck))
                            value.grayed = false

                            if (value.id === this.variantOptionsSelected?.[variant.code]) {
                                variant.selected = value
                            }
                        }
                    }

                    return {
                        ...variant,
                        values
                    }
                })

                return variantWidgets
            }
            return []
        },
    },

    async created(){
        this.clearDependenciesStore()

        try  {
            if (!this.areCategoriesLoaded) {
                await this.$store.dispatch('dataCatalogCategories/getCategories', {
                    institutionId: this.$_currentInstitutionId
                })
            }
            if (!this.residentPcp) {
                try {
                    await this.$store.dispatch('dataPcps/getResidentPcp', {
                        residentId: this.$_residentId
                    })
                } catch {
                    console.log('No shopping list for current resident')
                }
            }

            if (this.variant_id)  await this.getVariantProducts()
            else                  await this.getSingleProduct()

            this.checkIfCurrentSubscription()

        } catch (error) {
            console.error(error)
            this.$_showErrorToast(`Une erreur s'est produite pendant le chargement du produit.`)
        } finally {
            this.$_emitLoadRoute(true)
        }
    },

    beforeRouteLeave(_to, _from, next) {
        this.clearDependenciesStore()
        next()
    },

    methods: {
        async getSingleProduct(): Promise<void> {
            const singleProduct: EC_ProductOffers = await this.$store.dispatch('dataProducts/getProduct', {
                productId: this.product_id,
                institutionId: this.$_currentInstitutionId
            })

            if (singleProduct)
                this.updateProduct(singleProduct)
            else
                this.$_showErrorToast(`Une erreur s'est produite lors du chargement du produit`)
        },

        async getVariantProducts(): Promise<void> {
            const variantProducts = await this.$store.dispatch('dataProducts/getProducts', {
                variantCode: this.variant_id,
                institutionId: this.$_currentInstitutionId
            })
            if (variantProducts) {
                this.variantProducts = variantProducts.data || []
                this.variantMatrix = variantProducts.variantMatrix || {}
                this.variantData = variantProducts.variantData || []
                let defaultProduct = this.findVariantProductById(this.product_id)

                // Always choose the cheapest product
                if (this.variantProducts.length > 1 && this.variantData.length ) {
                    defaultProduct = this.cheapestVariantProductMatchSelection || defaultProduct
                }

                if (defaultProduct) this.updateProduct(defaultProduct)
                this.setVariantOptions()
            }
            else this.$_showErrorToast(`Une erreur s'est produite lors du chargement du produit`)
        },

        updateProduct(product: EC_ProductOffers): void {
            this.product = product?.product as EC_Product
            this.productId = product?.product.productSku
            this.productData = product?.product?.data as EC_ProductData
            this.offers = product?.offers as IOfferDto[]
            this.frontCategories = product?.frontCategoriesV2 as IFrontCategoryDto[]

            document.title =  this.productData.title || ''
        },

        setVariantOptions(): void {
            if (this.variantData.length) {
                this.variantOptions = this.variantData.map(variant => {
                    let values: VariantValue[] = []

                    if (variant.valuesData) {
                        values = Object.entries(variant.valuesData).map(
                            ([key, value]) => {
                                const productSkus = this.variantMatrix?.[variant.code ?? '']?.[key] ?? []
                                const image = productSkus.length ? this.findVariantProductImageById(productSkus[0]) : ''

                                return {
                                    id: key,
                                    label: value,
                                    productSkus,
                                    image,
                                    disabled: false,
                                    grayed: false
                                }
                            }
                        )
                    } else if (variant.values) {
                        values = variant.values.map(
                            (value) => {
                                const productSkus = this.variantMatrix?.[variant.code ?? '']?.[value] ?? []
                                const image = productSkus.length ? this.findVariantProductImageById(productSkus[0]) : ''

                                return {
                                    id: value,
                                    label: value,
                                    productSkus,
                                    image
                                }
                            }
                        )
                    }

                    return {
                        code: variant.code,
                        label: variant.label,
                        editorHint: variant.editorHint,
                        type: variant.editorType,
                        behaviour: variant.behaviour,
                        values
                    }
                }) as VariantOption[]

                //SET OPTIONS SELECTION
                const selection = {} as any
                this.variantOptions.forEach(vo => {
                    if (vo.behaviour === 'Pivot' || vo.values.length === 1) selection[vo.code] = this.productData?.[vo.code]?.[0]
                    else selection[vo.code] = ''
                })
                this.variantOptionsSelected = selection
            }
        },

        findVariantProductById(productId: string): EC_ProductOffers | undefined {
            return this.variantProducts && this.variantProducts.find(productOffer => productOffer?.product?.productSku === productId)
        },

        findVariantProductImageById(productId: string): string {
            const product =  this.variantProducts && this.variantProducts.find(productOffer => productOffer?.product?.productSku === productId)

            return product && product.product.data.mainImage?.source || ''
        },

        findVariantProductByOptions(options: ProductOption): EC_ProductOffers | undefined {
            const codesOfSelectedOptions = Object.keys(options).filter( code => Boolean(options[code]) )

            return this.variantProducts?.find(productOffer => {
                return codesOfSelectedOptions.every(code => {
                    const productOption = (productOffer?.product?.data as EC_ProductData)?.[code]
                    return productOption?.includes(options[code])
                })
            })
        },

        getPcpQuantityByProductId(productId: string, type: string): number {
            return this.$store.getters['dataPcps/getQuantity'](this.resident_id, productId, type)
        },

        scrollToDetails(): void {
            const detailsEl = (this.$refs.details as Vue).$el
            detailsEl.scrollIntoView({behavior: "smooth"})
        },

        async onAddToBasketBtnClick(): Promise<void> {
            if (this.checkAvailabilityToAddToBasket()) {
                if (this.errorInBuyBox) this.errorInBuyBox = ''

                try {
                    const draftProduct = {
                        id: this.productId,
                        type: 'product',
                        photo: this.productImages[0]?.source || '',
                        title: this.productData?.title || '',
                        description: this.productData?.shortDescription || '',
                        product_quantity: this.productOffer?.quantity || '',
                        legend: '',
                        price: this.productPrice,
                        frequency: this.subscriptionFrequency?.value || null,
                        quantity: this.quantity,
                        meta: {
                            product: this.product,
                            offer: this.productOffer,
                            variantInfo: this.variant_id ? ({
                                matrix: this.variantMatrix,
                                data: this.variantData
                            }) : {}
                        }
                    }

                    await this.$store.dispatch('dataDrafts/add', {
                        userId: this.$_currentUserId,
                        residentId: this.$_residentId,
                        type: this.buyMode.id,
                        draftProduct
                    })

                    if (this.$gtm.enabled()) {
                        this.$gtm.trackEvent({
                            event: 'add_to_cart',
                            currency: 'EUR',
                            value: this.productPrice * this.quantity,
                            ecommerce: {
                                items: [{
                                    item_id: this.product?.productSku,
                                    item_name: this.productData?.title || '',
                                    price: this.productPrice,
                                    itemcategory_: this.parentCategory?.name || '',
                                    item_category_2: this.category?.name || '',
                                    quantity: this.quantity
                                }]
                            },
                            buyMode: this.buyMode.id
                        })
                    }
                    document.body.scrollIntoView({behavior: "smooth"})
                }
                catch (error) {
                    console.error(error)
                    this.$_showErrorToast(`Une erreur s'est produite lors de l'ajout du produit dans le panier.`)
                }
            }
        },

        getResidentTutor (resident: IResidentDto) {
            return resident?.userInChargeOfResident
        },

        isResidentHasTutor (resident: IResidentDto) {
            return this.getResidentTutor(resident) != null
        },

        showSuggestionWarning (visible: boolean) {
            this.suggestionWarningOpen = visible
        },

        isEhpadUser (): boolean {
            return this.$aclChecker.some(this.$acl, [
                'isEhpadEmployee',
                'isEhpadManager'
            ])
        },

        goToResidentProfile() {
            this.$router.push({
                name: Routes.ResidentProfile,
                params: {
                    resident_id: this.$_resident.id,
                }
            })
        },

        async onSendSuggestionBtnClick(): Promise<void> {
            if (this.checkAvailabilityToAddToBasket()) {

                if (this.isEhpadUser() && !this.isResidentHasTutor(this.$_resident)) {
                    this.showSuggestionWarning(true)
                    return
                }

                if (this.errorInBuyBox) {
                    this.errorInBuyBox = ''
                }

                try {
                    await this.$store.dispatch('dataSuggestions/createProductSuggestion', {
                        residentId: this.$_residentId,
                        creatorName: this.suggestionEmitterName,
                        institutionId: this.$_currentInstitutionId,
                        buyMode: this.buyMode.id,
                        offerId: this.productOffer?.offerId,
                        frequencyInMonth: this.subscriptionFrequency?.value || null,
                        quantity: this.quantity
                    })

                    this.$store.dispatch('dataBuyBox/updateSuggestionEmitterName', '')

                    if (this.$gtm.enabled()) {
                        this.$gtm.trackEvent({
                            event: 'create_suggestion_catalog',
                            currency: 'EUR',
                            value: this.productPrice * this.quantity,
                            ecommerce: {
                                items: [{
                                    item_id: this.product?.productSku,
                                    item_name: this.productData?.title || '',
                                    price: this.productPrice,
                                    itemcategory_: this.parentCategory?.name || '',
                                    item_category_2: this.category?.name || '',
                                    quantity: this.quantity
                                }]
                            },
                            buyMode: this.buyMode.id
                        })
                    }
                    this.$_showInfoToast('Votre suggestion a bien été envoyée !')
                }
                catch (error) {
                    console.error(error)
                    this.$_showErrorToast(`Une erreur s'est produite lors de la création de la suggestion.`)
                }
            }
        },

        checkIfCurrentSubscription(): void {
            if (!this.residentPcp || !this.residentPcp.shoppingListProducts?.length) return

            const currentSubscription = this.residentPcp.shoppingListProducts.find(({ meta }) => this.productId === meta?.product?.productSku)

            if (currentSubscription) {
                this.$store.dispatch('dataBuyBox/updateIsCurrentSubscription', true)
                this.$store.dispatch('dataBuyBox/updateSubscriptionFrequency', {
                    value: currentSubscription.frequencyInMonth,
                    text: frequencies[currentSubscription.frequencyInMonth]
                })
            }
        },

        checkAvailabilityToAddToBasket(): boolean {
            if (this.variant_id && this.variantOptions.length) {
                return this.checkIfOptionsAreSelected()
            }
            return true
        },

        checkIfOptionsAreSelected(): boolean {
            const checkIfOptionsAreSelected = this.variantOptions.every(vo => Boolean(this.variantOptionsSelected?.[vo.code]))

            if (!checkIfOptionsAreSelected) {
                this.errorInBuyBox = 'Veuillez faire votre sélection parmi toutes les options'
                return false
            }
            return true
        },

        onWidgetChange(code: string, val: VariantValue): void {
            let optionsToCheck = {
                ...this.variantOptionsSelected,
                [code]: val.id
            }

            let matchProduct = this.findVariantProductByOptions(optionsToCheck)

            // If no matchProduct with all options selected then search product only with pivot options
            if (!matchProduct && this.checkOptionPivot(code)) {
                this.variantOptions.filter(vo => vo.behaviour !== 'Pivot').forEach(vo => {
                    optionsToCheck[vo.code] = ''
                })
                matchProduct = this.findVariantProductByOptions(optionsToCheck)

                // If still no matchProduct then search product only with THIS pivot option
                if (!matchProduct) {
                    this.variantOptions.filter(vo => vo.code !== code).forEach(vo => {
                        optionsToCheck[vo.code] = ''
                    })
                    matchProduct = this.findVariantProductByOptions(optionsToCheck)
                }
            }

            const matchProductId = matchProduct ? matchProduct.product.productSku : ''

            if (matchProduct && matchProductId) {
                this.updateProduct(matchProduct)
                this.variantOptionsSelected = optionsToCheck

                if (this.errorInBuyBox && this.checkAvailabilityToAddToBasket()) this.errorInBuyBox = ''

                if (this.product_id !== matchProductId) {
                    this.$router.replace({
                        params: {
                            ...this.$route.params,
                            product_id: matchProductId
                        },
                    })
                }
            } else {
                console.warn('Product page : something wrong => no product was found in onWidgetChange method')
            }
        },

        checkOptionPivot(code: string): boolean {
            return Boolean(this.variantOptions.find(vo => vo.code === code)?.behaviour === 'Pivot')
        },

        checkItemsForThumbSelector(widget: VariantOption): boolean {
            const { type, values } = widget
            if (type == 'Thumbnails') return true
            else if (values.length < 7 && values.findIndex(value => value.label.length > 12) === -1) return true
            return false
        },

        getWidgetDisableItemMessage(widget: VariantOption): string {
            if (widget.code !== 'color' && this.variantData.findIndex(k => k.code === 'color') !== -1) {
                return `Existe dans d'autres couleurs`
            }
            return ''
        },

        onVisibilityChangeATB(visibility: boolean): void {
            this.isVisibleButtonATB = visibility
        },

        parseText(str: string): string {
            return str.replace(/(\r\n|\n|\r)/g, "<br />")
        },

        clearDependenciesStore(): void {
            this.$store.dispatch('dataBuyBox/resetState')
        },
    },
})
