

















































import Vue, { PropType } from 'vue'

import { MediaGalleryImage } from '@/types/components'

import { HCard, HPopin, HCarousel } from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HCard,
        HPopin,
        HCarousel
    },
    props: {
        images: {
            type: [Array, Object] as PropType<MediaGalleryImage[]>,
            default: () => []
        },
        title: String
    },

    data(){
        return {
            selectedIndex: 0,
            popinModel: false
        }
    },

    computed: {
        mainImage(): MediaGalleryImage | null {
            return this.images[this.selectedIndex] || null
        },

        imagesForPopin(): MediaGalleryImage[] {
            const imagesForPopin = this.images.filter((_img, index) => index !== this.selectedIndex)
            const firstImage = this.images[this.selectedIndex]

            imagesForPopin.unshift(firstImage)

            return imagesForPopin
        }
    },

    methods: {
        onClickMainImg(): void {
            if (this.$breakpoint.mdAndUp) {
                this.popinModel = true
            }
        }
    }
})
